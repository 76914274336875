import { fetchUser } from "@/vue/apps/shared/api/auth.api";

export default {
  async fetchUser({ state, commit }) {
    const accessToken = state.accessToken;

    if (!accessToken) {
      return;
    }

    const { data: user } = await fetchUser(accessToken);

    commit("setUser", user);

    return user.id;
  },
};

export default {
  userId: (state) => state.user?.id,
  customerUuid: (state) => state.user?.pro?.uuid,
  accessToken: (state) => state.accessToken,
  locale: (state) => state.user.pro.locale,
  hasAccessToken: (state) => !!state.accessToken,
  permissions: (state) => {
    let permissions = state.permissions;
    const pro = state.user?.pro;
    const subscription = pro?.subscription;
    const subscriptionPermissions = subscription?.permissions;
    if (subscriptionPermissions) {
      permissions = permissions.concat(subscriptionPermissions);
    }
    return permissions;
  },
  can: (_state, getters) => (permission) =>
    getters.permissions.includes(permission),
  isAny:
    (state) =>
    (...roles) =>
      state.roles.includes(...roles),
  isAll:
    (state) =>
    (...roles) =>
      roles.every((role) => state.roles.includes(role)),
};

import { createLogger, createStore } from "vuex";

import auth from "@/vue/apps/shared/store/modules/auth/index.js";
import { getEnv } from "@/vue/utils/solvariConfig";

export default (baseStore) => {
  const store = createStore({
    ...baseStore,
    strict: true,
    modules: {
      auth: auth(),
      ...baseStore.modules,
    },
    plugins: getEnv().config.isDev ? [createLogger()] : [],
  });

  const data = document.querySelector("#vue-app-data-access-token")?.dataset
    .accessToken;

  if (data) {
    store.commit("auth/init", JSON.parse(data));
  }

  return store;
};

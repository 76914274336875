import axios from "axios";

import { getEnv } from "@/vue/utils/solvariConfig";
type User = {
  id: number;
};

export async function fetchUser(accessToken: string): Promise<User> {
  const url = `${getEnv().network.api.base}/v3/my/user`;
  const options: { headers?: { Authorization: string } } = {};

  if (accessToken) {
    options.headers = {
      Authorization: `Bearer ${accessToken}`,
    };
  }

  const response = await axios.get<User>(url, options);

  return response.data;
}
